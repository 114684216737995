import requestServer from '../request-server';

let internals = {};

internals.getAgentProfileDropdownValues = function () {
  return requestServer(
    `v1/api/profile/dropdown_values`,
    {},
    'GET',
    {},
    'application/json',
    true
  );
};

internals.addAgentDataForVerification = function (data) {
  return requestServer(
    'v1/user/agent/update',
    data,
    'PUT',
    {},
    'multipart/form-data',
    true
  );
};

internals.agentImageVerification = function (data) {
  return requestServer(
    'v1/agent/image/verification',
    data,
    'POST',
    {},
    'multipart/form-data',
    true
  );
};

internals.enhanceAgentProfile = function (data) {
  return requestServer(
    'v1/agent/profile/enhance',
    data,
    'PATCH',
    {},
    'application/json',
    true
  );
};

internals.getAgentProfileDetails = function (data) {
  return requestServer(
    'v1/agent/profile',
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.setAgentProfilePicture = function (data) {
  return requestServer(
    'v1/user/profile/image',
    data,
    'PUT',
    {},
    'multipart/form-data',
    true
  );
};

internals.deleteAgentProfilePicture = function () {
  return requestServer(
    'v1/user/profile/image',
    {},
    'DELETE',
    {},
    'application/json',
    true
  );
};

internals.viewAgentProfileQR = function (data) {
  return requestServer(
    `v1/agent/profile/view?customer_id=${data.agentId}`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.initiateAgentEKYC = function (data) {
  return requestServer(
    'v1/agent/initiate/ekyc',
    data,
    'POST',
    {},
    'application/json',
    true
  );
};

internals.updateAgentEKYCStatus = function (data) {
  return requestServer(
    'v1/agent/ekyc/status',
    data,
    'PATCH',
    {},
    'application/json',
    true
  );
};

internals.getAgentProfileCompletionStatus = function (data) {
  return requestServer(
    `v1/agent/profile/completion/details`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getPropertiesByAgentId = function (agentId) {
  return requestServer(
    `v1/agent/${agentId}/properties`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getAgentPropertyDetailsById = function ({ agentId, propertyId }) {
  return requestServer(
    `v1/agent/${agentId}/property/${propertyId}/details`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.checkIfPartnerExists = function (data) {
  return requestServer(
    'v1/api/partner_exists',
    data,
    'POST',
    {},
    'application/json',
    true
  );
};

internals.regenerateAgentDescription = function (data) {
  return requestServer(
    'v1/api/fill/agent/description',
    data,
    'POST',
    {},
    'application/json',
    true
  );
};

export default internals;
