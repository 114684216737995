export const HOME = '/';
export const LOGIN = '/login';
export const VERIFY_OTP = '/verify-otp';
export const OTP_SUCCESS = '/otp-success';
export const CHAT_WITH_AIRA = '/chat-with-aira';
export const UPDATE_DETAILS = '/update-details';
export const MESSAGES = '/messages';
export const DASHBOARD = '/dashboard';
export const ADD_PROPERTY = '/add-property';
export const PROFILE_ONBOARDING = '/profile-onboarding';
export const CREATE_PROFILE = '/create-profile';
export const INITIATE_EKYC = '/initiate-ekyc';
export const IMAGE_VERIFICATION = '/image-verification';
export const DECLARATION = '/declaration';
export const VIEW_PROFILE = '/profile';
export const UPDATE_PROFILE = '/update-profile';
export const PROPERTY_DETAILS = '/property/:propertyId';
export const FOUR_0_FOUR = '*';
