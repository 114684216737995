import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAPS_API_KEY } from '../../../constants/app-constants';
import { Search as SearchIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  CircularProgress,
  debounce,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import DropdownWithLabel from '../../../components/dropdownWithLabel';
import RadioSelect from '../../../components/radioSelect';

const AreasOfOperation = ({
  area,
  setFieldValue,
  dropdownValues,
  areasOfOperation,
  index,
  hideLabel = false,
}) => {
  const autocompleteServiceRef = useRef(null);

  const [placesOptions, setPlacesOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popularPlaces, setPopularPlaces] = useState([]);
  const [inputValue,setInputValue] =  useState('')

  useEffect(() => {
    if (dropdownValues?.popular_places) {
      const places = dropdownValues?.popular_places?.[area?.city]?.map(
        (place) => ({ value: place })
      );

      setPopularPlaces(places);
    }
  }, [dropdownValues?.popular_places, area?.city]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then(() => {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
    });
  }, []);

  const center = { lat: 50.064192, lng: -130.605469 };

  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };

  const handleInputChange = (e, val) => {
    setInputValue(val);
    debouncedFetchPredictions(val);
  };

  const fetchPredictions = (input) => {
    if (!input || !autocompleteServiceRef.current) return;

    setLoading(true);

    autocompleteServiceRef.current.getPlacePredictions(
      {
        // bounds: defaultBounds,
        // strictBounds: false,
        input,
        componentRestrictions: { country: 'in' },
        types: ['geocode'],
      },
      (predictions, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          setPlacesOptions(predictions);
        } else {
          setPlacesOptions([]);
        }
        setLoading(false);
      }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchPredictions = useCallback(
    debounce(fetchPredictions, 300),
    []
  );

  return (
    <Box sx={{ marginBottom: '1rem' }}>
      <DropdownWithLabel
        label={!hideLabel && 'Areas of Operation'}
        value={area.city}
        onChange={(e) => {
          let newArr = [...areasOfOperation];
          area.places = []
          newArr[index].city = e.target.value;
          setFieldValue('areasOfOperation', newArr);
        }}
        values={dropdownValues?.cities?.map((city) => city.value)}
      />
      <Typography
        sx={{
          fontSize: '16px',
          color: '#600DB4',
          marginTop: '-24px',
          fontStyle: 'italic',
          marginBottom: '12px',
        }}
      >
        (We’re currently available in these five cities and look forward to
        expanding soon)
      </Typography>

      {area.city && (
        <>
          <Autocomplete
            sx={{ marginTop: '1rem' }}
            freeSolo
            fullWidth
            options={placesOptions}
            getOptionLabel={(option) => option.description}
            filterOptions={(x) => x}
            loading={loading}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={(e, val) => {
              if (val) {
                const address = val.description?.split(',');
                if(val.description){
                  if(!popularPlaces.some((place) => place.value === address[0])){
                    setPopularPlaces([...popularPlaces,{ value: address[0]} ])
                  }
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search for areas"
                variant="outlined"
                fullWidth
                value={inputValue}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                       <span onClick={() => setInputValue("")}>
                                {params.InputProps.endAdornment}
                              </span>
                    </>
                  ),

                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <RadioSelect
            multiple
            name="areasOfOperation"
            value={area.places}
            dropdownValues={popularPlaces}
            setFieldValue={(val) => {
              const newArr = areasOfOperation.map((item) => {
                if (item.city === area.city) {
                  const isAlreadySelected = item.places.includes(val);
          
                  const updatedPlaces = isAlreadySelected
                    ? item.places.filter((place) => place !== val) 
                    : [...item.places, val]; 
          
                  return { ...item, places: updatedPlaces };
                }
          
                return item;
              });

              setFieldValue('areasOfOperation', newArr);
            }}
          />
        </>
      )}
    </Box>
  );
};

export default AreasOfOperation;
