import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Chip, Grid, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import './viewprofile.css';
import CircleIcon from '@mui/icons-material/Circle';
import UpdateProfile from '../UpdateProfile/UpdateProfile';
import Placeholder from '../Placeholder/Placeholder';
import apis from '../../../Services/api-services';
import GenerateQRModal from './components/GenerateQR';
import dayjs from 'dayjs';
import LoaderComponent from '../../../components/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { numDifferentiation } from '../../../Utils/helperUtils';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import AgentProfilePicture from '../components/AgentProfilePicture';
import { userActions } from '../../../Store/userSlice';
import PropertyDetail from '../../../components/propertyDetail';
import { handleProfileRedirection } from '../../../constants/enums';
import { IconMapPin } from '@tabler/icons-react';

const ProfileStat = ({ label, value, customerId }) => (
  <Grid item lg={customerId ? 12 : 3.8} xs={12}>
    <Typography sx={{ color: '#600DB4', fontSize: '24px', fontWeight: '600' }}>
      {value}
    </Typography>
    <Typography sx={{ color: '#474747', fontSize: '14px', fontWeight: '500' }}>
      {label}
    </Typography>
  </Grid>
);

const ChipGroup = ({ city, areas, backgroundColor, customerId }) => (
  <Box
    sx={{
      marginTop: '1rem',
    }}
  >
    <Typography
      sx={{
        color: '#474747',
        fontSize: '16px',
        fontWeight: '500',
        width: customerId ? '100%' : '23%',
        marginBottom: '16px',
      }}
    >
      {city}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: customerId ? '100%' : '75%',
        gap: 1,
      }}
    >
      {areas?.map((area, index) => (
        <Chip
          key={area}
          label={area}
          sx={{
            background: backgroundColor,
            color: '#474747',
            marginBottom: '.5rem',
            borderRadius: '8px',
          }}
        />
      ))}
    </Box>
  </Box>
);

const LanguageBox = ({ symbol, language }) => (
  <Typography
    sx={{
      color: '#474747',
      fontSize: '14px',
      fontWeight: '500',
      marginLeft: '.5rem',
    }}
  >
    {language} <span style={{ color: '#D2D2D2' }}>|</span>
  </Typography>
);

const testimonial = [];

const ViewProfile = ({ customerId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [selectedPropertyID, setSelectedPropertyID] = useState(null);
  const [openPropertyDetail, setOpenPropertyDetail] = useState(false);

  const agentId =
    new URLSearchParams(location.search).get('customer_id') || customerId;

  const { userId } = useSelector((state) => state.userDetails || {});

  const [loader, setLoader] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const [showGenerateQRModal, setShowGenerateQRModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [properties, setProperties] = useState(null);

  const MAX_LENGTH = 280;
  const areasOfOperation = JSON.parse(
    JSON.parse(agentDetails?.area_of_operation || null)
  );

  useEffect(() => {
    if (agentId) {
      getAgentDetailsById(agentId);
    } else {
      getAgentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  useEffect(() => {
    !agentId && handleProfileRedirection(navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agentDetails?.app_user_id) {
      getProperties(agentDetails);
    }
  }, [agentDetails]);

  const getAgentDetails = async () => {
    setLoader(true);
    const response = await apis.agent.getAgentProfileDetails();

    const agent = response?.data?.profile;
    setAgentDetails(agent);

    dispatch(userActions.setUserId(agent?.customer_id));
    dispatch(userActions.setUserName(agent?.full_name));
    dispatch(userActions.setIsUserVerified(agent?.is_verified));
    dispatch(userActions.setUserAvatar(agent?.profile_picture || ''));

    setLoader(false);
  };

  const getProperties = async () => {
    const response = await apis.property.getListingProperties();
    setProperties(response?.data?.properties);
  };

  const getAgentDetailsById = async (agentId) => {
    setLoader(true);
    const response = await apis.agent.viewAgentProfileQR({
      agentId,
    });

    const profile = response?.data?.profile;
    setAgentDetails(response?.data?.profile);

    if (profile?.customer_id) {
      const response = await apis.agent.getPropertiesByAgentId(
        profile?.customer_id
      );
      setProperties(response?.data?.properties);
    }

    setLoader(false);
  };

  const updateAgentProfilePicture = async (image) => {
    setLoader(true);

    const form = new FormData();
    form.append('profile_picture', image);

    const response = await apis.agent.setAgentProfilePicture(form);

    if (response?.data) {
      toast.success('Profile picture updated successfully');
      await getAgentDetails();
    }
    setLoader(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenPropertyDetail(newOpen);
  };

  // const deleteAgentProfilePicture = async () => {
  //   setLoader(true);

  //   const response = await apis.agent.deleteAgentProfilePicture();
  //   if (response?.data) {
  //     toast.success('Profile picture deleted successfully');
  //   }

  //   setLoader(false);
  // };

  const handleSelectedProperty = (id, target) => {
    if (target?.id !== 'property-item-save') {
      setSelectedPropertyID(id);
      setOpenPropertyDetail(true);
    }
  };

  return (
    <>
      {loader && <LoaderComponent />}

      <Box sx={{ background: '#fff', padding: '8rem 0' }}>
        <Box
          sx={{
            backgroundColor: '#F7F7F7',
            width: '90%',
            margin: '0 auto',
            borderRadius: '16px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px',
          }}
        >
          <Box
            sx={{
              height: '20px',
              background:
                'linear-gradient(112.19deg, #E1A3FF 0.98%, #590DB4 99.94%)',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
            }}
          />

          <Box sx={{ padding: { lg: '2rem', xs: '0.5rem' } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  lg: 'row',
                },
                justifyContent: 'space-between',
                alignItems: { xs: 'center', lg: 'stretch' },
                gap: { xs: '1rem', lg: 0 },
              }}
            >
              {/* avatar, name, location */}
              <Box>
                <AgentProfilePicture
                  userId={userId}
                  agentDetails={agentDetails}
                  updateAgentProfilePicture={updateAgentProfilePicture}
                />

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      color: '#474747',
                      fontSize: '1.5rem',
                      fontWeight: '600',
                    }}
                  >
                    {agentDetails?.full_name}
                  </Typography>

                  {agentDetails?.verification_status === 'Initiated' && (
                    <Typography
                      sx={{
                        padding: '6px 20px',
                        background: '#D2D2D2',
                        borderRadius: '24px',
                        marginLeft: '12px',
                        fontSize: '14px',
                      }}
                    >
                      Verification in progress
                    </Typography>
                  )}

                  {agentDetails?.is_verified && (
                    <VerifiedIcon
                      sx={{ color: '#600DB4', marginLeft: '.5rem' }}
                    />
                  )}
                </Box>
                {(agentDetails?.city || agentDetails?.state) && (
                  <Typography
                    sx={{
                      color: '#7D7D7D',
                      fontSize: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: '600',
                    }}
                  >
                    <IconMapPin size={18} />
                    {agentDetails?.city ? agentDetails?.city + ', ' : ''}
                    {agentDetails?.state ? agentDetails?.state : ''}
                  </Typography>
                )}
              </Box>

              {/* edit and share button */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  textAlign: 'right',
                  flexDirection: 'column',
                }}
              >
                {agentDetails?.customer_id === userId && (
                  <Box sx={{ display: 'flex' }}>
                    <Button
                      variant="outlined"
                      className="edit-profile-btn"
                      onClick={() => setOpenEditModal(true)}
                    >
                      Edit Profile
                    </Button>
                    {agentDetails?.is_verified && (
                      <Button
                        variant="contained"
                        className="share-profile-btn"
                        onClick={() => setShowGenerateQRModal(true)}
                      >
                        Share Digital Card
                      </Button>
                    )}
                  </Box>
                )}

                {agentDetails?.is_verified && (
                  <Typography sx={{ color: '#7D7D7D' }}>
                    <Typography sx={{ color: '#7D7D7D', marginTop: '20px' }}>
                      Agent ID :- {agentDetails?.customer_id}
                    </Typography>
                    Joined{' '}
                    <span style={{ color: '#7E0DB4' }}>
                      v<b>RE</b>fyd
                    </span>{' '}
                    on:{' '}
                    <b>
                      {dayjs(agentDetails?.created_at).format('DD MMM, YYYY')}
                    </b>
                  </Typography>
                )}
              </Box>
            </Box>

            <Grid
              container
              sx={{
                justifyContent: 'space-between',
                marginTop: '1rem',
              }}
            >
              {/* Left side */}
              <Grid item container lg={6.8} sm={12}>
                <Grid
                  item
                  lg={12}
                  sx={{
                    padding: '1rem',
                    borderRadius: '8px',
                    margin: '1rem 0 !important',
                    background: '#fff',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#474747',
                      textAlign: ' justify',
                      backgroundColor: '#fff',
                    }}
                  >
                    {isExpanded
                      ? agentDetails?.user_description
                      : agentDetails?.user_description?.slice(0, MAX_LENGTH)}
                    {agentDetails?.user_description?.length > MAX_LENGTH && (
                      <>
                        {!isExpanded && '...'}

                        <a
                          href="##"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsExpanded(!isExpanded);
                          }}
                          style={{
                            textDecoration: 'none',
                            color: '#7D7D7D',
                            marginLeft: '5px',
                          }}
                        >
                          {isExpanded ? 'see less' : 'see more'}
                        </a>
                      </>
                    )}
                  </Typography>
                </Grid>

                {/* active listing */}
                <Grid
                  item
                  lg={12}
                  sx={{
                    padding: '1rem',
                    borderRadius: '8px',
                    margin: '1rem 0 !important',
                    background: '#fff',
                  }}
                >
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      sx={{
                        color: '#474747',
                        fontSize: '20px',
                        fontWeight: '500',
                        margin: '1rem 0',
                      }}
                    >
                      Active Listings
                    </Typography>

                    {agentDetails?.properties_count === 0
                      ? agentDetails?.customer_id === userId && (
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              textTransform: 'capitalize',
                              borderRadius: '6px',
                              width: '20%',
                              height: '40px',
                            }}
                            onClick={() => navigate('/add-property')}
                          >
                            Start Listing
                          </Button>
                        )
                      : !customerId && (
                          <Button
                            variant="text"
                            className="view-all-btn"
                            onClick={() => navigate('/dashboard')}
                          >
                            View all
                          </Button>
                        )}
                  </Box>

                  {agentDetails?.properties_count === 0 && (
                    <Typography
                      sx={{
                        color: '#474747',
                        fontSize: '14px',
                        fontWeight: '400',
                        marginBottom: '1.5rem',
                      }}
                    >
                      No Active listings found, How about creating a listing
                      right now?
                    </Typography>
                  )}

                  <Grid container gap={2} justifyContent="flex-start">
                    {properties?.length > 0 ? (
                      properties?.slice(0, 4)?.map((item) => (
                        <Grid
                          item
                          lg={customerId ? 12 : 5.8}
                          md={3.8}
                          sm={12}
                          xs={12}
                          sx={{
                            borderRadius: '10px',
                            position: 'relative',
                            cursor: 'pointer',
                            width: '100%',
                          }}
                          onClick={(e) => {
                            console.log(item, 'item');
                            handleSelectedProperty(item.property_id, e.target);
                          }}
                        >
                          <Box
                            sx={{
                              height: '220px',
                              width: '100%',
                              borderRadius: '16px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#E7E3FC',
                              position: 'relative',
                            }}
                          >
                            <Typography sx={{ color: '#7D7D7D' }}>
                              Image not available
                            </Typography>
                            <img
                              src={item.cover_picture}
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '16px',
                                objectFit: 'cover',
                                position: 'absolute',
                              }}
                              alt=""
                            />
                          </Box>

                          <Box sx={{ width: '100%' }}>
                            <Typography
                              sx={{
                                color: '#474747',
                                fontSize: '18px',
                                fontWeight: '500',
                                marginTop: '1rem',
                              }}
                            >
                              {item.property_title}
                            </Typography>
                            <Typography
                              sx={{
                                color: '#7D7D7D',
                                fontSize: '16px',
                                fontWeight: '400',
                                marginTop: '.5rem',
                              }}
                            >
                              {item?.locality ? item?.locality + ', ' : ''}
                              {item?.city ? item?.city : ''}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              background: 'white',
                              position: 'absolute',
                              top: '50%',
                              right: '5%',
                              padding: '4px .5rem ',
                              borderRadius: '6px',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#600DB4',
                                fontSize: '20px',
                                fontWeight: '500',
                              }}
                            >
                              {numDifferentiation(item.sale_price || 0)}
                            </Typography>
                          </Box>
                        </Grid>
                      ))
                    ) : (
                      <Placeholder />
                    )}
                  </Grid>
                </Grid>

                {/*  Recent closed - remove the false condition later */}
                {false && (
                  <Box className="box">
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography
                        sx={{
                          color: '#474747',
                          fontSize: '20px',
                          fontWeight: '500',
                        }}
                      >
                        Recent closed on{' '}
                        <span style={{ color: '#600DB4' }}>vREfyd</span>
                      </Typography>

                      {agentDetails?.properties_count === 0 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate('/dashboard')}
                          sx={{
                            textTransform: 'capitalize',
                            width: '20%',
                            borderRadius: '6px',
                          }}
                        >
                          Find a property
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          className="view-all-btn"
                          onClick={() => navigate('/dashboard')}
                        >
                          View all
                        </Button>
                      )}
                    </Box>

                    {agentDetails?.closed_deals === 0 && (
                      <Typography
                        sx={{
                          color: '#474747',
                          fontSize: '14px',
                          fontWeight: '400',
                          marginBottom: '1.5rem',
                        }}
                      >
                        All the deals closed on vREfyd will appear here
                        automatically.
                      </Typography>
                    )}
                    <Grid container columnGap={2} sx={{ alignItems: 'center' }}>
                      {properties?.length > 0 ? (
                        properties?.map((item) => (
                          <>
                            <Grid
                              item
                              lg={3}
                              sx={{
                                borderRadius: '10px',
                                position: 'relative',
                              }}
                            >
                              <img
                                src={item.cover_picture}
                                style={{ width: '100%', borderRadius: '8px' }}
                                alt="property"
                              />
                              <Typography
                                sx={{
                                  color: '#474747',
                                  fontSize: '20px',
                                  fontWeight: '500',
                                }}
                              >
                                {item.property_title}
                              </Typography>
                              <Typography
                                sx={{
                                  color: '#7D7D7D',
                                  fontSize: '16px',
                                  fontWeight: '400',
                                }}
                              >
                                {item?.locality ? item?.locality + ', ' : ''}
                                {item?.city ? item?.city : ''}
                              </Typography>
                              <Box
                                sx={{
                                  background: 'white',
                                  position: 'absolute',
                                  top: '50%',
                                  right: '5%',
                                  padding: '4px .5rem ',
                                  borderRadius: '6px',
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: '#600DB4',
                                    fontSize: '20px',
                                    fontWeight: '500',
                                  }}
                                >
                                  ₹ {item.sale_price}
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        ))
                      ) : (
                        <Placeholder />
                      )}
                    </Grid>
                  </Box>
                )}
              </Grid>

              {/* Right side */}
              <Grid item container lg={5} sm={12}>
                {agentDetails?.customer_id === userId &&
                  agentDetails?.verification_status === 'Initiated' && (
                    <Typography
                      sx={{
                        justifySelf: 'end',
                        color: '#7D7D7D',
                        fontWeight: '600',
                      }}
                    >
                      Verification Initiated on:{' '}
                      {dayjs(agentDetails?.verification_initiated_on).format(
                        'DD MMM YYYY, hh:mm A'
                      )}
                    </Typography>
                  )}

                <Grid
                  item
                  container
                  lg={12}
                  sx={{
                    padding: '1rem',
                    borderRadius: '8px',
                    margin: '1rem 0 !important',
                    background: '#fff',
                    justifyContent: { lg: 'space-between', xs: 'center' },
                    alignItems: 'center',
                    gap: 1,
                    textAlign: 'center',
                  }}
                >
                  <ProfileStat
                    label="Years of Experience"
                    value={agentDetails?.years_of_experience || '-'}
                    customerId={customerId}
                  />
                  <ProfileStat
                    label="Properties Listed"
                    value={agentDetails?.properties_count || '-'}
                    customerId={customerId}
                  />
                  <ProfileStat
                    label="Closed Deals"
                    value={agentDetails?.closed_deals || '-'}
                    customerId={customerId}
                  />
                </Grid>

                <Grid item lg={12} className="box" sx={{ width: '100%' }}>
                  <Typography
                    sx={{
                      color: '#7D7D7D',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    Areas of Operation
                  </Typography>

                  {areasOfOperation?.map((area) => (
                    <ChipGroup
                      city={area.city}
                      areas={area.places || []}
                      backgroundColor="#F5EDFF"
                      customerId={customerId}
                    />
                  ))}
                </Grid>

                <Grid item lg={12} className="box" sx={{ width: '100%' }}>
                  <Typography
                    sx={{
                      color: '#7D7D7D',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    My Specialization
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '1rem',
                      flexWrap: 'wrap',
                      gap: '8px',
                    }}
                  >
                    {agentDetails?.specialization?.map((specialty, index) => (
                      <React.Fragment key={specialty}>
                        <Box sx={{ marginBottom: '4px' }}>
                          <Typography
                            sx={{
                              color: '#474747',
                              fontSize: '14px',
                              fontWeight: '500',
                            }}
                          >
                            {specialty}
                          </Typography>
                        </Box>
                        {index < 2 && (
                          <Typography sx={{ color: '#D2D2D2' }}>|</Typography>
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </Grid>

                <Grid item lg={12} className="box" sx={{ width: '100%' }}>
                  <Typography
                    sx={{
                      color: '#7D7D7D',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    Language(s)
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '1rem',
                      flexWrap: 'wrap',
                    }}
                  >
                    {agentDetails?.languages?.map((language) => (
                      <LanguageBox key={language} language={language} />
                    ))}
                  </Box>
                </Grid>

                {/* testimonials */}
                <Box
                  className="box"
                  sx={{ border: '1px solid #F5EDFF', display: 'none' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          color: '#474747',
                          fontSize: '1.5rem',
                          fontWeight: '500',
                        }}
                      >
                        Testimonial
                      </Typography>

                      {testimonial?.length < 1 && (
                        <Typography
                          sx={{
                            color: '#474747',
                            fontSize: '14px',
                            fontWeight: '400',
                            marginBottom: '1.5rem',
                          }}
                        >
                          Your clients feedback will be displayed here.
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Box>
                    {testimonial?.length < 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          textTransform: 'capitalize',
                          borderRadius: '6px',
                        }}
                      >
                        Share Feedback Form
                      </Button>
                    )}
                  </Box>

                  <Grid container rowGap={2}>
                    {testimonial?.map((item) => (
                      <Grid
                        item
                        lg={12}
                        sx={{
                          background: '#F8F8F8',
                          padding: '.5rem 1rem',
                          borderRadius: '8px',
                          marginTop: '1rem',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#474747',
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                        >
                          "{item?.remark}"
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '1rem',
                            columnGap: 1.2,
                          }}
                        >
                          <Avatar sx={{ width: 24, height: 24 }}>A</Avatar>
                          <Typography
                            sx={{ color: '#600DB4', fontSize: '14px' }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            sx={{ color: '#7D7D7D', fontSize: '12px' }}
                          >
                            <CircleIcon sx={{ fontSize: '6px' }} /> 4 days ago
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            {openEditModal && (
              <UpdateProfile
                open={openEditModal}
                agentDetails={agentDetails}
                getAgentDetails={() => getAgentDetails()}
                onClose={() => setOpenEditModal(false)}
                updateAgentProfilePicture={updateAgentProfilePicture}
              />
            )}
          </Box>
        </Box>

        <GenerateQRModal
          open={showGenerateQRModal}
          setOpen={setShowGenerateQRModal}
          agentDetails={agentDetails}
        />

        <PropertyDetail
          selectedPropertyID={selectedPropertyID}
          toggleDrawer={toggleDrawer}
          open={openPropertyDetail}
          setOpen={setOpenPropertyDetail}
          from="profile"
          externalAgentId={
            agentDetails?.customer_id === userId
              ? null
              : agentDetails?.customer_id
          }
        />
      </Box>
    </>
  );
};

export default ViewProfile;
