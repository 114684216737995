const ImagesLinks = {
  vrefydLogoWhite: require('../Assets/images/vREfyd_logo.png'),
  backgroundFrame: require('../Assets/images/autofill-with-aira-frame.png'),
  businessDetailsIcon: require('../Assets/images/business-details-icon.png'),
  realEstateProofIcon: require('../Assets/images/real-estate-proof-icon.png'),
  addressBookIcon: require('../Assets/images/address-book-icon.png'),
  indianFlag: require('../Assets/images/indian-flag.png'),
  securedByDesign: require('../Assets/images/secured-by-design-logo.png'),
  imageVerification: require('../Assets/images/image-verification.png'),
  cameraIcon: require('../Assets/images/camera-icon.png'),
  uploadPhotoIcon: require('../Assets/images/upload-photo-icon.png'),
  encryptedIcon: require('../Assets/images/encrypted-icon.png'),
  vrefydQrLogo: require('../Assets/images/vrefyd-qr-logo.png'),
  benefit1: require('../Assets/images/benefit-1.png'),
  benefit2: require('../Assets/images/benefit-2.png'),
  benefit3: require('../Assets/images/benefit-3.png'),
  benefit4: require('../Assets/images/benefit-4.png'),
  benefit5: require('../Assets/images/benefit-5.png'),
  benefit6: require('../Assets/images/benefit-6.png'),
  profileInfo: require('../Assets/images/profile-info.png'),
  docUploadFloorPlanIcon: require('../Assets/images/doc-upload-floor-plan-icon.png'),
  docUploadBrochureIcon: require('../Assets/images/doc-upload-brochure-icon.png'),
};

export default ImagesLinks;
