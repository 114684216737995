import React, { useEffect } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import LOCATIONROUNDED from '../Assets/images/location-rounded.svg';
import PROPERTYROUNDED from '../Assets/images/property-rounded.svg';
import CAPTUREROUNDED from '../Assets/images/capture-rounded.svg';
import MANROUNDED from '../Assets/images/man-rounded.svg';
import VIDEOROUNDED from '../Assets/images/video-rounded.svg';
import SUCCESSTICK from '../Assets/images/green-success-tick-rounded.svg';
import './property-verification-modal.css';
import ImagesLinks from '../Utils/ImagesLinks';
import { useDropzone } from 'react-dropzone';

const PropertyVerifyInstructions = ({ title, icon, content }) => {
  return (
    <div className="property-verify-instructions">
      <div className="property-verify-instructions-info">
        <img
          src={icon}
          alt="icon"
          className="property-verify-instructions-icon"
        />
        <span className="property-verify-instructions-title">{title}</span>
      </div>
      <ul className="property-verify-instructions-list">
        <li className="property-verify-instructions-list-item">{content}</li>
      </ul>
    </div>
  );
};

const CapturekeyDetails = () => {
  return (
    <div className="property-verify-instructions">
      <div className="property-verify-instructions-info">
        <img
          src={CAPTUREROUNDED}
          alt="icon"
          className="property-verify-instructions-icon"
        />
        <span className="property-verify-instructions-title">
          Capture Key Details
        </span>
      </div>
      <ul className="property-verify-instructions-list">
        <li className="property-verify-instructions-list-item">Clearly show</li>
        <ul className="property-verify-instructions-list">
          <li className="property-verify-instructions-list-item">
            The door or house number
          </li>
          <li className="property-verify-instructions-list-item">
            The apartment or society name
          </li>
          <li className="property-verify-instructions-list-item">
            The unit or block number
          </li>
          <li className="property-verify-instructions-list-item">
            The Verification Code
          </li>
        </ul>
        <li className="property-verify-instructions-list-item">
          These details should match the complete address provided for
          verification on your vREfyd listing.
        </li>
      </ul>
    </div>
  );
};

const MatchListingPhotos = () => {
  return (
    <div className="property-verify-instructions">
      <div className="property-verify-instructions-info">
        <img
          src={VIDEOROUNDED}
          alt="icon"
          className="property-verify-instructions-icon"
        />
        <span className="property-verify-instructions-title">
          Match the Listing Photos
        </span>
      </div>
      <ul className="property-verify-instructions-list">
        <li className="property-verify-instructions-list-item">
          Ensure the video looks like the photos in your listing. The layout,
          furniture, and fixtures should be the same to confirm accuracy.
        </li>
        <li>Property-Specific Tips</li>
        <ul className="property-verify-instructions-list">
          <li className="property-verify-instructions-list-item">
            <strong>Apartments:</strong> Focus on the building name, unit
            number, and each room
          </li>
          <li className="property-verify-instructions-list-item">
            <strong>Villas & Houses: </strong> Show the entrance, house number,
            and all main areas.
          </li>
          <li className="property-verify-instructions-list-item">
            <strong>Gated Communities:</strong> Record the community name,
            block, and any shared spaces.
          </li>
          <li className="property-verify-instructions-list-item">
            <strong>Commercial Spaces (Offices, Shops):</strong> Show the
            business name, unit number, and the main area layout.{' '}
          </li>
        </ul>
      </ul>
    </div>
  );
};

const PropertyVerification = ({
  onClose,
  videoPreview,
  setVideoPreview,
  videoFile,
  setVideoFile,
  verificationSuccess,
  verificationCode = '',
}) => {
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(videoPreview); // Clean up on component unmount
    };
  }, [videoPreview]);

  const onDrop = (video) => {
    handleVideoUpload(video);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'video/*': [],
    },
  });

  const handleVideoUpload = (file) => {
    if (file?.[0] && file?.[0]?.type.startsWith('video/')) {
      setVideoFile(file?.[0]);
      setVideoPreview(URL.createObjectURL(file?.[0]));
    } else {
      alert('Please upload a valid video file.');
    }
  };

  const handleRemoveVideo = () => {
    setVideoFile(null);
    setVideoPreview(null);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        maxWidth: 600,
        borderRadius: 2,
        outline: 'none',
        // height: '80%',
        overflow: 'scroll',
        margin: 2,
      }}
    >
      <Box sx={{ p: 3, margin: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <ArrowBackIcon
              onClick={() => onClose()}
              sx={{
                border: '1px solid #D2D2D2',
                borderRadius: '4px',
                padding: '4px',
                fontSize: '2rem',
                cursor: 'pointer',
              }}
            />
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                color: '#474747',
                fontSize: '16px',
                fontWeight: '500',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              Property Verification
              {/* <span style={{ color: 'red', marginLeft: 2 }}>*</span> */}
              <img
                src={ImagesLinks.securedByDesign}
                width={'94px'}
                style={{ marginLeft: '5px' }}
                className="property-verification-secured-by-design"
                alt="secured-by-design"
              />
            </Typography>
          </Box>

          {!verificationSuccess && (
            <Button
              variant="contained"
              startIcon={<HelpOutlineIcon />}
              sx={{
                background: '#F4F0DF',
                color: '#474747',
                boxShadow: 'none',
                padding: '8px 1rem',
                borderRadius: '6px',
                textTransform: 'capitalize',
                ':hover': {
                  color: '#fff',
                },
              }}
            >
              Help
            </Button>
          )}
        </Box>
        {verificationSuccess ? (
          <Box
            sx={{
              minHeight: '80vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '60%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={SUCCESSTICK} width={50} height={50} alt="success" />
              <Typography
                sx={{
                  color: '#474747',
                  my: 1,
                  fontSize: '20px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                Verification Initiated!
              </Typography>
              <Typography
                sx={{
                  color: '#474747',
                  fontSize: '14px',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                Sit back and relax, We will let you know once the property is
                verified
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                my: 2,
                background: '#F4F0DF',
                padding: 2,
                borderRadius: 1,
              }}
            >
              <Typography
                sx={{ color: '#474747', fontSize: '1rem', fontWeight: '600' }}
              >
                Why Verification is Important?
              </Typography>
              <Typography sx={{ color: '#474747', fontSize: '14px' }}>
                We allow only{' '}
                <strong style={{ color: '#590DB4' }}>vREFyd</strong> properties
                to avoid duplication of properties.
              </Typography>
            </Box>
            <Box sx={{ my: 3 }}>
              <Typography>
                Verification Code:{' '}
                <strong style={{ color: '#590DB4' }}>
                  {' '}
                  {verificationCode}
                </strong>
              </Typography>
              <Typography sx={{ color: '#A03C42', fontSize: '15px' }}>
                To verify, you must write this code on a piece of paper and show
                it clearly in the video. Without this step, the video will not
                be accepted.
              </Typography>
            </Box>

            <Box
              {...getRootProps()}
              sx={{
                my: 3,
                padding: '3rem 1rem',
                border: '1px dashed #600DB4',
                textAlign: 'center',
                borderRadius: 1,
                position: 'relative',
                backgroundColor: isDragActive
                  ? '#f0e7ff'
                  : 'rgba(96, 13, 180, 0.1)',
              }}
            >
              {!videoPreview ? (
                <>
                  <UploadFileOutlinedIcon
                    sx={{ fontSize: '2rem', color: '#600DB4' }}
                  />

                  {isDragActive ? (
                    <Typography>Drop here</Typography>
                  ) : (
                    <Typography variant="body1" mt={2}>
                      Drag and Drop or{' '}
                      <label
                        htmlFor="video-upload"
                        style={{ color: '#600DB4', cursor: 'pointer' }}
                      >
                        Click to Upload
                      </label>
                    </Typography>
                  )}

                  <input {...getInputProps()} />
                </>
              ) : (
                <Box position="relative">
                  <video
                    src={videoPreview}
                    controls
                    autoPlay
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  />
                  <IconButton
                    onClick={handleRemoveVideo}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: '#fff',
                      color: '#000',
                      '&:hover': { backgroundColor: '#600DB4', color: '#fff' },
                    }}
                  >
                    <CloseIcon sx={{}} />
                  </IconButton>
                </Box>
              )}
            </Box>

            <Box sx={{ mt: 5 }}>
              <Typography
                sx={{
                  color: '#590DB4',
                  fontSize: '14px',
                  fontWeight: '500',
                  marginBottom: '1.5rem',
                }}
              >
                Important : Make sure this is a single, continuous video
                recording.
              </Typography>
              {/* <Grid container spacing={2}>
              {steps?.map((item) => (
                <Grid item xs={3.9}>
                  <Card
                    sx={{
                      boxShadow: '2px 2px 10px 0px #AAAAAA40',
                      borderRadius: '10px',
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          width: '3rem',
                          height: '3rem',
                          margin: '0 auto',
                        }}
                      >
                        <img
                          src={item.icon}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          color: '#474747',
                          fontSize: '14px',
                          fontWeight: '400',
                          marginTop: '1rem',
                        }}
                      >
                        {item.text}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid> */}
              <div className="property-instruction-container">
                <PropertyVerifyInstructions
                  title={'Start Outside - Show the Location'}
                  icon={LOCATIONROUNDED}
                  content={
                    'Begin by recording the property’s surrounding area. Show nearby street signs, any recognizable buildings, or address details on neighboring properties to confirm the exact location.'
                  }
                />
                <PropertyVerifyInstructions
                  title={'Show Access to the Property'}
                  icon={PROPERTYROUNDED}
                  content={
                    'Record yourself (or an authorized representative) unlocking the door. Include any nameplates or logos on the door or entrance area to show that you have permission to enter.'
                  }
                />
                <CapturekeyDetails />
                <PropertyVerifyInstructions
                  title={'Walk Through the Entire Property'}
                  icon={MANROUNDED}
                  content={
                    'Record each room, including key areas like the living room, kitchen, and bedrooms. Show any special features like balconies or studies to give a full view of the property.'
                  }
                />
                <MatchListingPhotos />
              </div>
            </Box>
          </>
        )}
      </Box>

      {/* <Box
          sx={{
            boxShadow: '0px -1px 26.3px -10px #00000040',
            marginTop: '3rem',
            textAlign: 'center',
          }}
        >
          <Typography
            align="center"
            sx={{
              color: '#A03C42',
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: '500',
              paddingTop: '1rem',
            }}
          >
            *Failure to follow these guidelines will result in failed
            verification.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              margin: '1rem 0',
              padding: '6px 2rem',
              borderRadius: '8px',
              textTransform: 'capitalize',
              fontWeight: '600',
            }}
          >
            Submit
          </Button>
        </Box> */}
    </Box>
  );
};

export default PropertyVerification;
