import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBW_lJjaNYZ5M1ukj_Oc3hUNj5LebcdxdU',
  authDomain: 'vrefyd-dde94.firebaseapp.com',
  projectId: 'vrefyd-dde94',
  storageBucket: 'vrefyd-dde94.firebasestorage.app',
  messagingSenderId: '400263168912',
  appId: '1:400263168912:web:9a80b3ee90b08845a66915',
  measurementId: 'G-LQK5W8PLKG',
};

const VAPID_KEY =
  'BCApbHf4k5mnE6zEN_LJNtoN00uPIOmjgRNz7jNh1CDncoxiFFa7ELIHDVkmwxprnDqUfmJLvGOyk0ka7Na8Mno';

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const initializeServiceWorker = async () => {
  try {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.register(
        '../firebase-messaging-sw.js'
      );
      console.log('Service Worker registered successfully:', registration);
      return registration;
    } else {
      console.error('Service Worker is not supported in this browser.');
    }
  } catch (error) {
    console.error('Error registering Service Worker:', error);
  }
};

export const requestFcmToken = async () => {
  try {
    const registration = await initializeServiceWorker();
    if (!registration) {
      throw new Error('Service Worker registration failed');
    }

    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY,
      });
      if (token) {
        console.log(token, 'fcm token is here --------');

        return token;
      } else {
        console.error('No registration token available.');
      }
    } else {
      console.error('Notification permission not granted.');
    }
  } catch (error) {
    console.error('Error getting FCM token:', error);
  }
};
