import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './SignupLoginForm.css'; // Ensure you have this CSS file for custom styles
import INDIANFLAG from '../../Assets/images/indian-flag.png';
import { VERIFY_OTP } from '../../constants/route-path';
import apis from '../../Services/api-services';
import { useDispatch } from 'react-redux';
import { userActions } from '../../Store/userSlice';
import { ALERT_TYPES } from '../../constants/app-constants';
import LoginWrapper from '../../components/loginWrapper';
import { RotatingLines } from 'react-loader-spinner';

const SignupLoginForm = (props) => {
  const [mobile, setMobile] = useState('');
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false); // State to control error Snackbar
  const [alertMsg, setAlertmsg] = useState('');
  const [alertType, setAlertType] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const regexOnlyNumber = /^[0-9]*$/;
  const wrapperProps = {
    openSnackbar,
    setOpenSnackbar,
    setAlertmsg,
    alertType,
    alertMsg,
  };

  const validateForm = () => {
    const newErrors = {};
    if (!mobile) {
      newErrors.mobile = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(mobile)) {
      newErrors.mobile = 'Enter a valid 10-digit Indian mobile number';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    if (!validateForm()) return;
    const payload = { mobile, channel: 'android' };
    setLoading(true);
    apis.user.sendOtp(payload).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        dispatch(userActions.setUserDetails({ mobile }));

        navigate(VERIFY_OTP);
      } else {
        setAlertmsg('Login Failed!');
        setAlertType(ALERT_TYPES.error);
        setOpenSnackbar(true);
        console.error('Signup failed:', response);
      }
    });
  };

  const handleInputChange = (value, id) => {
    let newErrors = {};
    switch (id) {
      case 'mobile':
        if (regexOnlyNumber.test(value) && value.length < 11) {
          setMobile(value);
        }
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  return (
    <LoginWrapper {...wrapperProps}>
      <div className="signup-login-form-content">
        <span className="sign-up-label">Enter Mobile Number</span>
        <div
          className={`input-container ${errors.mobile ? 'input-error' : ''}`}
        >
          <img src={INDIANFLAG} alt="India Flag" className="flag-icon" />
          <span className="country-code">+91</span>
          <input
            type="text"
            placeholder="9999999999"
            id="mobile"
            value={mobile}
            disabled={loading}
            onChange={(e) => handleInputChange(e.target.value, e.target.id)}
            className={`signup-inputs `}
            autocomplete="off"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
        </div>
        <span className="signup-error-msg">{errors.mobile}</span>
        <Button
          type="submit"
          variant="contained"
          className="signup-btn"
          color="primary"
          onClick={() => {
            if (!loading) {
              handleSubmit();
            }
          }}
          sx={{ textTransform: 'capitalize', gap: '4px' }}
          fullWidth
        >
          {loading ? 'Submitting' : 'Submit'}

          <RotatingLines
            visible={loading}
            height="24"
            width="24"
            strokeColor="#fff"
            strokeWidth="5"
            animationDuration="0.7"
          />
        </Button>
      </div>
    </LoginWrapper>
  );
};

export default SignupLoginForm;
