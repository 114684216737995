import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import React from 'react';
import apis from '../Services/api-services';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';

const buttonEnums = {
  Home: 'Got to homescreen',
  RealtorProfile: `View Profile`,
  PropertyDetails: `View Property`,
  ChatListings: 'View Chats',
  ListProperty: 'Add Property',
};

export default function Notifications({
  open,
  onClose,
  notifications,
  getNotifications,
  isLoading,
}) {
  const navigate = useNavigate();

  const handleMarkReadNotification = async (notificationId) => {
    const response = await apis.notification.markReadNotification({
      notificationId,
    });
    if (response?.data?.status === 'success') {
      getNotifications();
    }
  };

  const handleNavigateOnNotificationClick = async (data) => {
    const params = JSON.parse(data?.params || null);

    const propertyId = params?.propertyId;
    const notificationId = params?.notification_id;

    if (notificationId) {
      await handleMarkReadNotification(notificationId);
    }

    const routes = {
      Home: '/',
      RealtorProfile: `/profile`,
      PropertyDetails: `/property/${propertyId}`,
      ChatListings: '/messages',
      ListProperty: '/add-property',
    };

    if (data?.screen) {
      onClose();
      navigate(routes[data?.screen]);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '8px',
          maxHeight: 800,
          position: 'absolute',
          right: '17%',
          top: '10%',
          width: '42%',
          outline: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '2rem 1rem 1rem 1rem',
          }}
        >
          <Typography
            sx={{
              marginRight: '1rem',
              color: '#474747',
              fontSize: '18px',
              fontWeight: '600',
            }}
          >
            Notifications{' '}
            {notifications?.length ? `(${notifications?.length})` : ''}
          </Typography>

          <IconX
            strokeWidth="1.5"
            size="32"
            style={{ cursor: 'pointer' }}
            onClick={() => onClose()}
          />
        </Box>

        <Box>
          <Divider sx={{ margin: '0 1rem 1rem 1rem' }} />
        </Box>

        {isLoading ? (
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{ color: '#7D7D7D', padding: '1rem 1rem 1rem 1rem' }}
            >
              Loading Notifications....
            </Typography>

            <RotatingLines
              visible={isLoading}
              height="24"
              width="24"
              strokeColor="#600DB4"
              strokeWidth="5"
              animationDuration="0.7"
            />
          </Box>
        ) : (
          <Box sx={{ maxHeight: '500px', overflow: 'scroll' }}>
            {notifications?.length > 0 ? (
              notifications.map((notification, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: '#F5EDFF',
                    },
                  }}
                  onClick={() => {
                    handleNavigateOnNotificationClick(notification?.data);
                  }}
                >
                  <Box sx={{ padding: '1rem 1rem' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: '#474747',
                        fontWeight: '600',
                      }}
                    >
                      {notification?.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#474747',
                      }}
                    >
                      {notification?.message}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: '4px' }}>
                        <Typography sx={{ color: '#7D7D7D', fontSize: '14px' }}>
                          {dayjs(notification?.created_at).format('DD/MM/YYYY')}{' '}
                          |{' '}
                        </Typography>
                        <Typography sx={{ color: '#7D7D7D', fontSize: '14px' }}>
                          {dayjs(notification?.created_at).format('hh:mm A')}
                        </Typography>
                      </Box>
                      {buttonEnums[notification?.data?.screen] && (
                        <Button
                          variant="text"
                          onClick={() =>
                            handleNavigateOnNotificationClick(
                              notification?.data
                            )
                          }
                          sx={{
                            color: '#600DB4',
                            fontSize: '14px',
                            textTransform: 'initial',
                            textDecoration: 'underline',
                          }}
                        >
                          {buttonEnums[notification?.data?.screen] ||
                            'Click here'}
                        </Button>
                      )}
                    </Box>
                  </Box>

                  <Box>
                    <Divider sx={{ marginBotom: '1rem' }} />
                  </Box>
                </Box>
              ))
            ) : (
              <Typography
                sx={{ color: '#7D7D7D', padding: '1rem 1rem 1rem 1rem' }}
              >
                No notification found
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
