import requestServer from '../request-server';

let internals = {};

// To call the API
// internals.fetchVehicleDetails = function (data) {
//     return requestServer(
//         'login',                            -----> Base URL
//         data,                               -----> Data if any
//         'GET',                              -----> Method (GET/POST/PUT)
//         {},                                 ------> Headers if any (can be passes as {...})
//         'application/json',                 ------> Content Type
//         true                                ------> whether Authentication required(access token)
//         true                                ------> whether refresh token required
//     );
// };

internals.getUserData = function (data) {
  return requestServer('v1/users', data, 'GET', {}, 'application/json', true);
};

internals.sendOtp = function (data) {
  return requestServer(
    'v1/users/send_otp',
    data,
    'POST',
    {},
    'application/json',
    false
  );
};

internals.updateUser = function (data) {
  return requestServer(
    'v1/user/update',
    data,
    'PATCH',
    {},
    'application/json',
    true
  );
};

internals.verifyOtp = function (data) {
  return requestServer(
    'v1/users/verify_otp',
    data,
    'POST',
    {
      'Grant-Type': 'Bearer',
    },
    'application/json',
    false
  );
};

internals.getRefreshToken = function (data) {
  return requestServer(
    'v1/users/refresh_token',
    data,
    'POST',
    {
      'Grant-Type': 'refresh_token',
    },
    'application/json',
    false,
    true
  );
};

internals.resendOtp = function (data) {
  return requestServer(
    'aira/api/resend-otp',
    data,
    'POST',
    {},
    'application/json',
    false
  );
};

export default internals;
