import { Box, Typography } from '@mui/material';
import React from 'react';

export default function PushNotification({ notification }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
      }}
    >
      <Typography
        style={{
          color: '#474747',
          fontWeight: 600,
          fontSize: '14px',
        }}
      >
        {notification?.title}
      </Typography>
      <Typography style={{ color: '#474747', fontSize: '14px' }}>
        {notification?.body}
      </Typography>
    </Box>
  );
}
