import React from 'react';
import vREFYD_WHITE from '../Assets/images/brand-logo-white.png';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from '../constants/route-path';
import './loginWrapper.css';
import AlertMessage from './alertmessage';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const LoginWrapper = (props) => {
  const {
    showBack = false,
    openSnackbar,
    setOpenSnackbar,
    setAlertmsg,
    alertType,
    alertMsg,
  } = props;

  const navigate = useNavigate();

  return (
    <div className="login-container">
      <div className="login-container-logo">
        <img
          src={vREFYD_WHITE}
          alt="vREFyd Logo"
          className="login-input-brand-logo"
        />
      </div>
      <div className="login-hero-section">
        <span className="login-hero-main-title">
          Find, Connect, Close Faster.
        </span>
        <span className="login-hero-section-info">
          Access <span className="login-hero-section-info-vrefyd">vREfyd</span>{' '}
          Properties, Trusted Community, and AI Tools for Quick, Secure Deals.
        </span>
      </div>
      <div className="login-input-container-wrapper">
        <div className="login-input-container">
          {showBack && (
            <div onClick={() => navigate(LOGIN)} className="login-back-button">
              <KeyboardBackspaceIcon className="back-btn" />
            </div>
          )}

          <div className="login-input-content">
            {React.cloneElement(props.children, { ...props })}
          </div>
        </div>
        <div>
          <span className="login-footer-note">
            {' '}
            You're currently using an internal Beta version.
          </span>
        </div>
      </div>

      <AlertMessage
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        setAlertmsg={setAlertmsg}
        alertType={alertType}
        alertMsg={alertMsg}
      />
    </div>
  );
};

export default LoginWrapper;
