import requestServer from '../request-server';

let internals = {};

internals.getNotifications = function (data) {
  return requestServer(
    'v1/user/notifications',
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.markReadNotification = function ({ notificationId }) {
  return requestServer(
    'v1/api/mark/notification',
    { notification_id: notificationId },
    'PATCH',
    {},
    'application/json',
    true
  );
};

internals.userNotificationFcmTokenUpdate = function (mobile, fcmToken) {
  return requestServer(
    'v1/user/token/update',
    { fcm_token: fcmToken, mobile },
    'PATCH',
    {},
    'application/json',
    true
  );
};

export default internals;
