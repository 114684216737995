import React, { useEffect, useState } from "react";
import PropertyListItem from "./propertyListItem";
import { Button } from "@mui/material";

const RecommendationsListing = (props) => {
  const {
    title,
    subtitle,
    data = [],
    from,
    handleSelectedProperty,
    showDays,
  } = props;
  let firstSix = data.slice(0, 6);

  const [showAll, setShowAll] = useState(false);
  const [currentData, setCurrentdata] = useState(firstSix);

  useEffect(()=>{
    !showAll ? setCurrentdata(firstSix) : setCurrentdata(data);
  },[data])

  const handleViewAllClick = () => {
    setShowAll(!showAll);
    showAll ? setCurrentdata(firstSix) : setCurrentdata(data);
  };

  return (
    <div className="recomendation-listing">
      <span className="recomendation-listing-title">{title}</span>
      <span className="recomendation-listing-subtitle">{subtitle}</span>
      <div className="recomendation-listing-data">
        {currentData.map((item, index) => (
          <PropertyListItem
            showDays={showDays}
            key={item.property_id}
            {...item}
            index={index}
            parentClasses={"addmar-30"}
            handleSelectedProperty={handleSelectedProperty}
            from="home"
          />
        ))}
      </div>
      {data?.length > 6 && <div className="recomendation-listing-view-all">
        <Button
          onClick={() => {
            handleViewAllClick();
          }}
          type="submit"
          sx={{
            width: 200,
            background: "#fffff",
            border: "1px solid ",
            fontSize: "12px",
            textTransform: "none",
            m: 1,
          }}
        >
          {showAll ? "Hide" : "View All"}
        </Button>
      </div>}
    </div>
  );
};

export default RecommendationsListing;
